<template>
  <section class="container">
    <div v-if="isSuccess">
      <router-link
        to="/point"
        class="mb-3"
      >
        <v-btn>
          kembali
        </v-btn>
      </router-link>
      <div class="mt-3 mt-3 text-center">
        <span style="font-size:16pt">Selamat, anda berhasil menukar poin</span>
      </div>
      <div class="mt-3 mb-3">
        Redeem ID: {{ this.history.redeem_id }} <br>
        Nama: {{ this.fullname }} <br>
        Email: {{ this.email }} <br>
        Handphone: {{ this.phone }} <br>
        Tanggal Penukaran: {{ format_date(this.history.created_at) }}
      </div>
      <span>Jumlah poin yang ditukar: <span style="font-size: 18pt">{{ this.history.amount * (-1) }}</span></span><br>
      <span>Point Setara: Rp. {{ this.history.point_equal.toLocaleString() }}</span><br>
      <span>Sisa Poin: {{ this.history.current_point }}</span>
      <div class="mt-5" />
      <h5>Petunjuk Penukaran Poin</h5>
      <div v-html="this.description" />

      <div id="footer-redeem">
        <a
          :href="'https://wa.me/' + this.whatsapp + '?text=Hai, saya ingin menukarkan poin dengan kode: ' + this.history.redeem_id"
          target="_blank"
        >Hubungi WhatsApp</a>
      </div>
    </div>
  </section>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'
  export default {

    name: 'RedeemHistory',

    data () {
      return {
        id: null,
        userId: null,
        history: {},
        fullname: '',
        email: '',
        phone: '',
        description: null,
        oke: 'fas',
        isSuccess: false,
        whatsapp: null,
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    mounted: function () {
      this.id = this.$route.query.id
      this.userId = localStorage.userId
      this.getUser()
      this.getHistory()
    },

    methods: {
      getUser: function () {
        // axios.get('http://localhost:8000/api/qr/user/'+this.userId).then(response  => {
        axios.get('https://backoffice.fitsea.co.id/api/qr/user/' + this.userId).then(response => {
          this.fullname = response.data.fullname
          this.email = response.data.email
          this.phone = response.data.phone
        })
      },
      getHistory: function () {
        // axios.get('http://localhost:8000/api/qr/history/'+this.id).then(response  => {
        axios.get('https://backoffice.fitsea.co.id/api/qr/history/' + this.id).then(response => {
          this.history = response.data.data
          this.description = response.data.description
          this.whatsapp = response.data.whatsapp
          this.isSuccess = response.data.success
        })
      },

      format_date (value) {
        if (value) {
          return moment(String(value)).format('DD MMM YYYY, HH:mm')
        }
      },

    },
  }
</script>
<style scoped>
#footer-redeem {
    position: fixed;
    bottom: 0;
    z-index: 6;
    width: 100%;
    left: 0;
    background-color: #128c7e;
    color: #000 !important;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

#footer-redeem a {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none !important;
}
</style>
